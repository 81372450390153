import { BaseEntity } from "./base_entity";
import { BlogPostCategory } from "./blog_post_category";
import { BlogPostSubcategory } from "./blog_post_subcategory";
import { Media } from "./media";

export enum IdealMktCategories {
	COMMEMORATIVE_DATE = "Datas Especiais",
	THEMES = "Temas",
	MARKETING = "Peças de Marketing",
	TEMPLATES = "Templates",
}

export class BlogPost extends BaseEntity<BlogPost> {
	photos?: Media[];
	mainCategory: BlogPostCategory;
	subcategory?: BlogPostSubcategory;
	title: string;
	isEditable: boolean;
	content: string;
	eventDate?: Date;
	coverUrl: string;
	sourceFilesUrl?: string;
}
