// eslint-disable-next-line no-unused-vars
// import Vue from "vue";
import Vue from "../main";
import ToastificationContent from "../@core/components/toastification/ToastificationContent.vue";
import { MimeTypes } from "@/interfaces/mime";

// export async function loadingWrap(vueContext: Vue, functionToExecute: () => Promise<any>, successMessage = "", errorMessage = "Ocorreu um erro. Tente novamente.") {
// 	await vueContext.$vs.loading();
// 	try {
// 		await functionToExecute();
// 		if (successMessage) {
// 			vueContext.$vs.notify({ color: "success", title: "Sucesso", text: successMessage });
// 		}
// 	} catch (error) {
// 		console.error(error);
// 		vueContext.$vs.notify({ color: "danger", title: "Erro", text: errorMessage });
// 	}
// 	await vueContext.$vs.loading.close();
// }

export async function requireConfirmation(
	title = "Deseja prosseguir?",
	text = "Não será possível reverter essa operação",
	acceptText = "Continuar",
) {
	const confirmationResult = await Vue.$bvModal.msgBoxConfirm(text, {
		title,
		size: "sm",
		okVariant: "danger",
		okTitle: acceptText,
		cancelTitle: "Cancelar",
		cancelVariant: "outline-secondary",
		hideHeaderClose: true,
		centered: true,
	});

	if (!confirmationResult) {
		throw new Error("Operation cancelled");
	}
}

export function showAlert(content: { text: string; title: string; variant: string; icon: string }) {
	return Vue.$toast({
		component: ToastificationContent,
		props: {
			title: content.title,
			text: content.text,
			variant: content.variant,
			icon: content.icon,
		},
	});
}

export function showSuccessAlert(text: string, title = "Sucesso") {
	return showAlert({ text, title, variant: "success", icon: "CheckCircleIcon" });
}

export function showErrorAlert(text: string, title = "Erro") {
	return showAlert({ text, title, variant: "danger", icon: "AlertCircleIcon" });
}

export function removeDiacritics(value: string) {
	return value
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase();
}

export function errorMessageOnAddMultipleFiles(addedFilesLength: number, notAddedFilesLength: number) {
	let message = `Arquivo não adicionado`;

	if (notAddedFilesLength > 1 && addedFilesLength === notAddedFilesLength) {
		message = `Arquivos não adicionados!`;
	} else if (addedFilesLength !== notAddedFilesLength && addedFilesLength > 1 && notAddedFilesLength > 1) {
		message = `Não foi possível adicionar alguns arquivos!`;
	} else if (addedFilesLength !== notAddedFilesLength && addedFilesLength > 1 && notAddedFilesLength === 1) {
		message = `Não foi possível adicionar um dos arquivos!`;
	}

	return message;
}

export const mimeTypes: MimeTypes = {
	images: { mimes: ["image/png", "image/jpeg"] },
	compressed: {
		mimes: ["application/zip", "application/octet-stream", "application/x-zip-compressed", "multipart/x-zip"],
	},
	spreadsheet: {
		mimes: [
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"application/vnd.google-apps.spreadsheet	",
		],
		extensions: ["xlsx", "xls"],
	},
};
