




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ICMArrowButton extends Vue {
	@Prop({ default: "left" }) pointing: "left" | "right";
	@Prop({ default: "40px" }) size: string;
	@Prop({ default: "#672E80" }) color: string;

	position = {
		right: "180deg",
		left: "0",
	};

	get setPointing() {
		return { "--degree": this.position[this.pointing], "--size": this.size, "--color": this.color };
	}
}
