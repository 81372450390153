












import { Component, Prop, Vue } from "vue-property-decorator";
import { format, parseISO } from "date-fns";
import { BCardTitle } from "bootstrap-vue";

import PostCard from "./PostCard.vue";
import ICMSlider from "../../base/ICMSlider.vue";

import { BlogPost } from "@/interfaces/blog_post";

interface PostsCarouselProps extends Omit<BlogPost, "eventDate"> {
	category: string;
	eventDate: string;
}

@Component({
	components: {
		BCardTitle,
		PostCard,
		"icm-slider": ICMSlider,
	},
})
export default class PostsCarousel extends Vue {
	@Prop({ required: true, default: [] }) posts: BlogPost[];
	@Prop({ required: true }) sectionTitle: string;
	@Prop({ default: "id" }) carouselId: string;
	@Prop({ default: false }) noTitle: boolean;
	@Prop({ default: true }) useCarousel: boolean;

	get formattedPosts(): PostsCarouselProps[] {
		return this.posts.map(post => {
			return {
				...post,
				category: post.mainCategory.name,
				eventDate: post.eventDate ? format(parseISO(String(post.eventDate)), "dd/MM") : "",
			};
		});
	}
}
