








































import { Component, Prop, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperClass, { SwiperOptions } from "swiper";

import ICMArrowButton from "./ICMArrowButton.vue";

interface SwiperInstance extends Element {
	$swiper: SwiperClass;
}

interface DataFormat {
	id: string;
	[key: string]: any;
}

@Component({ components: { Swiper, SwiperSlide, "icm-arrow-button": ICMArrowButton } })
export default class ICMSlider extends Vue {
	@Prop({ default: () => [] }) data: DataFormat[];
	@Prop({ default: () => {} }) swiperOptions: any;
	@Prop({ default: "slideId" }) slideId: string;
	@Prop({ default: "" }) title: string;
	@Prop({ default: false }) noTitle: boolean;
	@Prop({ default: true }) useCarousel: boolean;
	@Prop({ default: true }) autoHideArrows: boolean;
	@Prop({ default: "#672E80" }) arrowColor: string;

	get defaultSwiperOptions(): SwiperOptions {
		return {
			slidesPerView: "auto",
			spaceBetween: 40,
			navigation: {
				nextEl: "#next-" + this.slideId,
				prevEl: "#prev-" + this.slideId,
			},
			...this.swiperOptions,
		};
	}

	get swiper() {
		return (this.$refs.swiper as SwiperInstance)?.$swiper;
	}

	mounted() {
		this.$emit("slide", this.swiper);
	}

	handleEvents(isAnimating: boolean, fireSlideEvent = true) {
		this.$emit("isAnimating", isAnimating);

		if (fireSlideEvent) {
			this.$emit("slide", this.swiper);
		}
	}
}
