





















import { Component, Prop, Vue } from "vue-property-decorator";
import { BImg, BCard, BCardBody, BButton, BCardTitle, BCardText, BSkeletonImg } from "bootstrap-vue";

@Component({ components: { BImg, BCard, BCardBody, BButton, BCardTitle, BCardText, BSkeletonImg } })
export default class PostCard extends Vue {
	@Prop({ required: true }) id: string;
	@Prop({ default: "" }) title: string;
	@Prop({ default: "" }) category: string;
	@Prop({ default: "" }) eventDate?: string;
	@Prop({ default: "" }) coverUrl: string;

	isLoadingCover = true;
}
